











import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import ProgressBar from '@/components/generic/ProgressBar.vue'
import Header from '@/components/generic/Header.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'

const appointmentStatusNamespace = namespace('appointmentStatus')

@Component({
  components: {
    ProgressBar,
    Header,
    CallToAction
  }
})
export default class CancelSuccess extends Vue {
  @appointmentStatusNamespace.Getter('cancellationSuccessText') cancellationSuccessText!: string | null

  mounted() {
    this.$store.dispatch('appointmentStatus/fetchCancellationText')
  }
}
